// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.181.2
//   protoc               v3.19.1
// source: organization.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Avatar, Filter_Organizations, Pagination, Pagination_Result, Timestamp } from "./base";
import { Value_Numeric_Rate } from "./value";

export const protobufPackage = "hiber.organization";

/**
 * Organization data. An Organization can have many linked users, but the organization is the owner
 * of any modems and related data.
 */
export interface Organization {
  /** The slug for this organization, used to identify organizations */
  organization: string;
  /** The name of the organization to display to the end-user */
  displayName: string;
  address: Organization_Address | undefined;
  contact: Organization_Contact | undefined;
  vatNumber: string;
  billingName: string;
  billingAddress: Organization_Address | undefined;
  contractSignatureDate: Timestamp | undefined;
  createdAt: Timestamp | undefined;
  updatedAt: Timestamp | undefined;
  features: Organization_Feature[];
  databaseInfo: string;
  defaults?: Organization_Defaults | undefined;
}

export enum Organization_Feature {
  UNKNOWN = "UNKNOWN",
  /** MODEM_CREATION - Required to manually create modems using the ModemService. */
  MODEM_CREATION = "MODEM_CREATION",
  /** EARLY_ACCESS - Used for organizations that get early access to features. */
  EARLY_ACCESS = "EARLY_ACCESS",
  /** EXPERIMENTAL - Used for organizations that get access to experimental features. e.g. feature work in progress. */
  EXPERIMENTAL = "EXPERIMENTAL",
  /** ASSETS - Access the list of assets in Mission Control. */
  ASSETS = "ASSETS",
  /** ASSET_DASHBOARD - Use the new assets as primary data owner in Mission Control dashboards. */
  ASSET_DASHBOARD = "ASSET_DASHBOARD",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function organization_FeatureFromJSON(object: any): Organization_Feature {
  switch (object) {
    case 0:
    case "UNKNOWN":
      return Organization_Feature.UNKNOWN;
    case 4:
    case "MODEM_CREATION":
      return Organization_Feature.MODEM_CREATION;
    case 5:
    case "EARLY_ACCESS":
      return Organization_Feature.EARLY_ACCESS;
    case 6:
    case "EXPERIMENTAL":
      return Organization_Feature.EXPERIMENTAL;
    case 10:
    case "ASSETS":
      return Organization_Feature.ASSETS;
    case 11:
    case "ASSET_DASHBOARD":
      return Organization_Feature.ASSET_DASHBOARD;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Organization_Feature.UNRECOGNIZED;
  }
}

export function organization_FeatureToNumber(object: Organization_Feature): number {
  switch (object) {
    case Organization_Feature.UNKNOWN:
      return 0;
    case Organization_Feature.MODEM_CREATION:
      return 4;
    case Organization_Feature.EARLY_ACCESS:
      return 5;
    case Organization_Feature.EXPERIMENTAL:
      return 6;
    case Organization_Feature.ASSETS:
      return 10;
    case Organization_Feature.ASSET_DASHBOARD:
      return 11;
    case Organization_Feature.UNRECOGNIZED:
    default:
      return -1;
  }
}

export interface Organization_Address {
  lines: string[];
  zipCode: string;
  city: string;
  state: string;
  country: string;
}

export interface Organization_Contact {
  name: string;
  email: string;
  phone: string;
}

/** Default settings for this organization. */
export interface Organization_Defaults {
  /** The default expected transmission interval for devices in this organization. */
  expectedDeviceTransmissionRate?: Value_Numeric_Rate | undefined;
}

/**
 * Selection object for child organizations.
 * User for child organization list and tree.
 */
export interface OrganizationSelection {
  organizations?: Filter_Organizations | undefined;
  search?: string | undefined;
}

/** Get your current organization's data */
export interface GetOrganizationRequest {
  /** Pick the organization to get the details for. If unset, your default organization is used. */
  organization?: string | undefined;
}

/**
 * Update organization data.
 * All fields are effectively optional, though address, billing_address, contact and features are assumed to be complete objects,
 * not partial updates.
 * Note that the organization field specifies the organization, it is not used to update the current organization identifier.
 */
export interface UpdateOrganizationRequest {
  organization?: string | undefined;
  displayName: string;
  vatNumber: string;
  address: Organization_Address | undefined;
  billingName: string;
  billingAddress: Organization_Address | undefined;
  contact: Organization_Contact | undefined;
  avatar: Avatar | undefined;
}

/**
 * Get your current organization's organization tree.
 * The organization tree contains your current organization as the root of the tree, with all child organizations ordered below it.
 */
export interface OrganizationTreeRequest {
  /** Pick the organization to use (/impersonate) for this call, overriding your default organization */
  organization?: string | undefined;
  selection: OrganizationSelection | undefined;
}

export interface OrganizationTreeRequest_Response {
  request: OrganizationTreeRequest | undefined;
  tree: OrganizationTree | undefined;
}

export interface OrganizationTree {
  organization: Organization | undefined;
  children: OrganizationTree[];
}

/** List the child organizations for the given organization */
export interface ListChildOrganizationsRequest {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** Select the organizations to list. Optional, when omitted or empty everything is included. */
  selection?: OrganizationSelection | undefined;
  pagination?: Pagination | undefined;
  includeDetails?: boolean | undefined;
}

export interface ListChildOrganizationsRequest_Response {
  childOrganizations: Organization[];
  request: ListChildOrganizationsRequest | undefined;
  pagination: Pagination_Result | undefined;
}

export interface CreateOrganizationRequest {
  /**
   * Pick the organization to use as parent. If unset, your default organization is used.
   * If you have no organization, an organization_creation_token is required.
   */
  parentOrganization: string;
  /**
   * The name for the new organization. Lowercase, letters, numbers, dashes and underscores only. Required.
   * Used as an identifier for the organization.
   */
  newOrganization: string;
  /** The name to display for your organization (i.e. capitalized, with spaces, etc.). Default to the name above. */
  displayName?:
    | string
    | undefined;
  /** The avatar image representing this organization. Usually the logo. */
  avatar?:
    | Avatar
    | undefined;
  /** Whether this organization is created for a business. */
  isBusiness?:
    | boolean
    | undefined;
  /** Whether this organization is created for a business, provide a VAT number. */
  vatNumber?:
    | string
    | undefined;
  /** Postal address for your organization. */
  address?:
    | Organization_Address
    | undefined;
  /** Billing information for your organization. Optional, but required if you want active modems. */
  billingName?:
    | string
    | undefined;
  /** Billing address for your organization. Optional, but required if you want active modems. */
  billingAddress?:
    | Organization_Address
    | undefined;
  /** Contact information for your organization. Required. */
  contact:
    | Organization_Contact
    | undefined;
  /** A token that allows you to create an organization without having an organization. */
  organizationCreationToken?: string | undefined;
}

export interface DeleteOrganizationRequest {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  parentOrganization: string;
  /** The organization to delete. Required. */
  organizationToDelete: string;
}

export interface DeleteOrganizationRequest_Response {
  organizationToDelete: string;
  /** The organizations that were deleted. */
  organizationsDeleted: OrganizationTree | undefined;
}

export interface ValidateOrganizationCreationTokenRequest {
  /** A token that allows you to create an organization without having an organization. */
  organizationCreationToken: string;
}

export interface ValidateOrganizationCreationTokenRequest_Response {
  valid: boolean;
}

export interface GetOrganizationAvatar {
}

export interface GetOrganizationAvatar_Request {
  /** The slug for this organization, used to identify organizations */
  organizations: string[];
  pagination?: Pagination | undefined;
}

export interface GetOrganizationAvatar_Response {
  /** Avatars, indexed by organization slug */
  avatars: { [key: string]: Avatar };
  pagination: Pagination_Result | undefined;
}

export interface GetOrganizationAvatar_Response_AvatarsEntry {
  key: string;
  value: Avatar | undefined;
}

function createBaseOrganization(): Organization {
  return {
    organization: "",
    displayName: "",
    address: undefined,
    contact: undefined,
    vatNumber: "",
    billingName: "",
    billingAddress: undefined,
    contractSignatureDate: undefined,
    createdAt: undefined,
    updatedAt: undefined,
    features: [],
    databaseInfo: "",
    defaults: undefined,
  };
}

export const Organization = {
  encode(message: Organization, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== "") {
      writer.uint32(10).string(message.organization);
    }
    if (message.displayName !== "") {
      writer.uint32(18).string(message.displayName);
    }
    if (message.address !== undefined) {
      Organization_Address.encode(message.address, writer.uint32(34).fork()).ldelim();
    }
    if (message.contact !== undefined) {
      Organization_Contact.encode(message.contact, writer.uint32(58).fork()).ldelim();
    }
    if (message.vatNumber !== "") {
      writer.uint32(26).string(message.vatNumber);
    }
    if (message.billingName !== "") {
      writer.uint32(42).string(message.billingName);
    }
    if (message.billingAddress !== undefined) {
      Organization_Address.encode(message.billingAddress, writer.uint32(50).fork()).ldelim();
    }
    if (message.contractSignatureDate !== undefined) {
      Timestamp.encode(message.contractSignatureDate, writer.uint32(66).fork()).ldelim();
    }
    if (message.createdAt !== undefined) {
      Timestamp.encode(message.createdAt, writer.uint32(74).fork()).ldelim();
    }
    if (message.updatedAt !== undefined) {
      Timestamp.encode(message.updatedAt, writer.uint32(82).fork()).ldelim();
    }
    writer.uint32(90).fork();
    for (const v of message.features) {
      writer.int32(organization_FeatureToNumber(v));
    }
    writer.ldelim();
    if (message.databaseInfo !== "") {
      writer.uint32(98).string(message.databaseInfo);
    }
    if (message.defaults !== undefined) {
      Organization_Defaults.encode(message.defaults, writer.uint32(106).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Organization {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrganization();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.displayName = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.address = Organization_Address.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.contact = Organization_Contact.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.vatNumber = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.billingName = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.billingAddress = Organization_Address.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.contractSignatureDate = Timestamp.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.createdAt = Timestamp.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.updatedAt = Timestamp.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag === 88) {
            message.features.push(organization_FeatureFromJSON(reader.int32()));

            continue;
          }

          if (tag === 90) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.features.push(organization_FeatureFromJSON(reader.int32()));
            }

            continue;
          }

          break;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.databaseInfo = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.defaults = Organization_Defaults.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Organization>): Organization {
    return Organization.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Organization>): Organization {
    const message = createBaseOrganization();
    message.organization = object.organization ?? "";
    message.displayName = object.displayName ?? "";
    message.address = (object.address !== undefined && object.address !== null)
      ? Organization_Address.fromPartial(object.address)
      : undefined;
    message.contact = (object.contact !== undefined && object.contact !== null)
      ? Organization_Contact.fromPartial(object.contact)
      : undefined;
    message.vatNumber = object.vatNumber ?? "";
    message.billingName = object.billingName ?? "";
    message.billingAddress = (object.billingAddress !== undefined && object.billingAddress !== null)
      ? Organization_Address.fromPartial(object.billingAddress)
      : undefined;
    message.contractSignatureDate =
      (object.contractSignatureDate !== undefined && object.contractSignatureDate !== null)
        ? Timestamp.fromPartial(object.contractSignatureDate)
        : undefined;
    message.createdAt = (object.createdAt !== undefined && object.createdAt !== null)
      ? Timestamp.fromPartial(object.createdAt)
      : undefined;
    message.updatedAt = (object.updatedAt !== undefined && object.updatedAt !== null)
      ? Timestamp.fromPartial(object.updatedAt)
      : undefined;
    message.features = object.features?.map((e) => e) || [];
    message.databaseInfo = object.databaseInfo ?? "";
    message.defaults = (object.defaults !== undefined && object.defaults !== null)
      ? Organization_Defaults.fromPartial(object.defaults)
      : undefined;
    return message;
  },
};

function createBaseOrganization_Address(): Organization_Address {
  return { lines: [], zipCode: "", city: "", state: "", country: "" };
}

export const Organization_Address = {
  encode(message: Organization_Address, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.lines) {
      writer.uint32(10).string(v!);
    }
    if (message.zipCode !== "") {
      writer.uint32(18).string(message.zipCode);
    }
    if (message.city !== "") {
      writer.uint32(26).string(message.city);
    }
    if (message.state !== "") {
      writer.uint32(34).string(message.state);
    }
    if (message.country !== "") {
      writer.uint32(42).string(message.country);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Organization_Address {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrganization_Address();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.lines.push(reader.string());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.zipCode = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.city = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.state = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.country = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Organization_Address>): Organization_Address {
    return Organization_Address.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Organization_Address>): Organization_Address {
    const message = createBaseOrganization_Address();
    message.lines = object.lines?.map((e) => e) || [];
    message.zipCode = object.zipCode ?? "";
    message.city = object.city ?? "";
    message.state = object.state ?? "";
    message.country = object.country ?? "";
    return message;
  },
};

function createBaseOrganization_Contact(): Organization_Contact {
  return { name: "", email: "", phone: "" };
}

export const Organization_Contact = {
  encode(message: Organization_Contact, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.email !== "") {
      writer.uint32(18).string(message.email);
    }
    if (message.phone !== "") {
      writer.uint32(26).string(message.phone);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Organization_Contact {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrganization_Contact();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.email = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.phone = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Organization_Contact>): Organization_Contact {
    return Organization_Contact.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Organization_Contact>): Organization_Contact {
    const message = createBaseOrganization_Contact();
    message.name = object.name ?? "";
    message.email = object.email ?? "";
    message.phone = object.phone ?? "";
    return message;
  },
};

function createBaseOrganization_Defaults(): Organization_Defaults {
  return { expectedDeviceTransmissionRate: undefined };
}

export const Organization_Defaults = {
  encode(message: Organization_Defaults, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.expectedDeviceTransmissionRate !== undefined) {
      Value_Numeric_Rate.encode(message.expectedDeviceTransmissionRate, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Organization_Defaults {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrganization_Defaults();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.expectedDeviceTransmissionRate = Value_Numeric_Rate.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Organization_Defaults>): Organization_Defaults {
    return Organization_Defaults.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Organization_Defaults>): Organization_Defaults {
    const message = createBaseOrganization_Defaults();
    message.expectedDeviceTransmissionRate =
      (object.expectedDeviceTransmissionRate !== undefined && object.expectedDeviceTransmissionRate !== null)
        ? Value_Numeric_Rate.fromPartial(object.expectedDeviceTransmissionRate)
        : undefined;
    return message;
  },
};

function createBaseOrganizationSelection(): OrganizationSelection {
  return { organizations: undefined, search: undefined };
}

export const OrganizationSelection = {
  encode(message: OrganizationSelection, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organizations !== undefined) {
      Filter_Organizations.encode(message.organizations, writer.uint32(10).fork()).ldelim();
    }
    if (message.search !== undefined) {
      writer.uint32(18).string(message.search);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OrganizationSelection {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrganizationSelection();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organizations = Filter_Organizations.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.search = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<OrganizationSelection>): OrganizationSelection {
    return OrganizationSelection.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<OrganizationSelection>): OrganizationSelection {
    const message = createBaseOrganizationSelection();
    message.organizations = (object.organizations !== undefined && object.organizations !== null)
      ? Filter_Organizations.fromPartial(object.organizations)
      : undefined;
    message.search = object.search ?? undefined;
    return message;
  },
};

function createBaseGetOrganizationRequest(): GetOrganizationRequest {
  return { organization: undefined };
}

export const GetOrganizationRequest = {
  encode(message: GetOrganizationRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetOrganizationRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetOrganizationRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<GetOrganizationRequest>): GetOrganizationRequest {
    return GetOrganizationRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<GetOrganizationRequest>): GetOrganizationRequest {
    const message = createBaseGetOrganizationRequest();
    message.organization = object.organization ?? undefined;
    return message;
  },
};

function createBaseUpdateOrganizationRequest(): UpdateOrganizationRequest {
  return {
    organization: undefined,
    displayName: "",
    vatNumber: "",
    address: undefined,
    billingName: "",
    billingAddress: undefined,
    contact: undefined,
    avatar: undefined,
  };
}

export const UpdateOrganizationRequest = {
  encode(message: UpdateOrganizationRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.displayName !== "") {
      writer.uint32(18).string(message.displayName);
    }
    if (message.vatNumber !== "") {
      writer.uint32(26).string(message.vatNumber);
    }
    if (message.address !== undefined) {
      Organization_Address.encode(message.address, writer.uint32(34).fork()).ldelim();
    }
    if (message.billingName !== "") {
      writer.uint32(42).string(message.billingName);
    }
    if (message.billingAddress !== undefined) {
      Organization_Address.encode(message.billingAddress, writer.uint32(50).fork()).ldelim();
    }
    if (message.contact !== undefined) {
      Organization_Contact.encode(message.contact, writer.uint32(58).fork()).ldelim();
    }
    if (message.avatar !== undefined) {
      Avatar.encode(message.avatar, writer.uint32(66).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateOrganizationRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateOrganizationRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.displayName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.vatNumber = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.address = Organization_Address.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.billingName = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.billingAddress = Organization_Address.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.contact = Organization_Contact.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.avatar = Avatar.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateOrganizationRequest>): UpdateOrganizationRequest {
    return UpdateOrganizationRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateOrganizationRequest>): UpdateOrganizationRequest {
    const message = createBaseUpdateOrganizationRequest();
    message.organization = object.organization ?? undefined;
    message.displayName = object.displayName ?? "";
    message.vatNumber = object.vatNumber ?? "";
    message.address = (object.address !== undefined && object.address !== null)
      ? Organization_Address.fromPartial(object.address)
      : undefined;
    message.billingName = object.billingName ?? "";
    message.billingAddress = (object.billingAddress !== undefined && object.billingAddress !== null)
      ? Organization_Address.fromPartial(object.billingAddress)
      : undefined;
    message.contact = (object.contact !== undefined && object.contact !== null)
      ? Organization_Contact.fromPartial(object.contact)
      : undefined;
    message.avatar = (object.avatar !== undefined && object.avatar !== null)
      ? Avatar.fromPartial(object.avatar)
      : undefined;
    return message;
  },
};

function createBaseOrganizationTreeRequest(): OrganizationTreeRequest {
  return { organization: undefined, selection: undefined };
}

export const OrganizationTreeRequest = {
  encode(message: OrganizationTreeRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.selection !== undefined) {
      OrganizationSelection.encode(message.selection, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OrganizationTreeRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrganizationTreeRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.selection = OrganizationSelection.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<OrganizationTreeRequest>): OrganizationTreeRequest {
    return OrganizationTreeRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<OrganizationTreeRequest>): OrganizationTreeRequest {
    const message = createBaseOrganizationTreeRequest();
    message.organization = object.organization ?? undefined;
    message.selection = (object.selection !== undefined && object.selection !== null)
      ? OrganizationSelection.fromPartial(object.selection)
      : undefined;
    return message;
  },
};

function createBaseOrganizationTreeRequest_Response(): OrganizationTreeRequest_Response {
  return { request: undefined, tree: undefined };
}

export const OrganizationTreeRequest_Response = {
  encode(message: OrganizationTreeRequest_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.request !== undefined) {
      OrganizationTreeRequest.encode(message.request, writer.uint32(10).fork()).ldelim();
    }
    if (message.tree !== undefined) {
      OrganizationTree.encode(message.tree, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OrganizationTreeRequest_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrganizationTreeRequest_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.request = OrganizationTreeRequest.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.tree = OrganizationTree.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<OrganizationTreeRequest_Response>): OrganizationTreeRequest_Response {
    return OrganizationTreeRequest_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<OrganizationTreeRequest_Response>): OrganizationTreeRequest_Response {
    const message = createBaseOrganizationTreeRequest_Response();
    message.request = (object.request !== undefined && object.request !== null)
      ? OrganizationTreeRequest.fromPartial(object.request)
      : undefined;
    message.tree = (object.tree !== undefined && object.tree !== null)
      ? OrganizationTree.fromPartial(object.tree)
      : undefined;
    return message;
  },
};

function createBaseOrganizationTree(): OrganizationTree {
  return { organization: undefined, children: [] };
}

export const OrganizationTree = {
  encode(message: OrganizationTree, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      Organization.encode(message.organization, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.children) {
      OrganizationTree.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OrganizationTree {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrganizationTree();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = Organization.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.children.push(OrganizationTree.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<OrganizationTree>): OrganizationTree {
    return OrganizationTree.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<OrganizationTree>): OrganizationTree {
    const message = createBaseOrganizationTree();
    message.organization = (object.organization !== undefined && object.organization !== null)
      ? Organization.fromPartial(object.organization)
      : undefined;
    message.children = object.children?.map((e) => OrganizationTree.fromPartial(e)) || [];
    return message;
  },
};

function createBaseListChildOrganizationsRequest(): ListChildOrganizationsRequest {
  return { organization: undefined, selection: undefined, pagination: undefined, includeDetails: undefined };
}

export const ListChildOrganizationsRequest = {
  encode(message: ListChildOrganizationsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.selection !== undefined) {
      OrganizationSelection.encode(message.selection, writer.uint32(18).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination.encode(message.pagination, writer.uint32(26).fork()).ldelim();
    }
    if (message.includeDetails !== undefined) {
      writer.uint32(32).bool(message.includeDetails);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListChildOrganizationsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListChildOrganizationsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.selection = OrganizationSelection.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.pagination = Pagination.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.includeDetails = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListChildOrganizationsRequest>): ListChildOrganizationsRequest {
    return ListChildOrganizationsRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListChildOrganizationsRequest>): ListChildOrganizationsRequest {
    const message = createBaseListChildOrganizationsRequest();
    message.organization = object.organization ?? undefined;
    message.selection = (object.selection !== undefined && object.selection !== null)
      ? OrganizationSelection.fromPartial(object.selection)
      : undefined;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination.fromPartial(object.pagination)
      : undefined;
    message.includeDetails = object.includeDetails ?? undefined;
    return message;
  },
};

function createBaseListChildOrganizationsRequest_Response(): ListChildOrganizationsRequest_Response {
  return { childOrganizations: [], request: undefined, pagination: undefined };
}

export const ListChildOrganizationsRequest_Response = {
  encode(message: ListChildOrganizationsRequest_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.childOrganizations) {
      Organization.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.request !== undefined) {
      ListChildOrganizationsRequest.encode(message.request, writer.uint32(18).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination_Result.encode(message.pagination, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListChildOrganizationsRequest_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListChildOrganizationsRequest_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.childOrganizations.push(Organization.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.request = ListChildOrganizationsRequest.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.pagination = Pagination_Result.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListChildOrganizationsRequest_Response>): ListChildOrganizationsRequest_Response {
    return ListChildOrganizationsRequest_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListChildOrganizationsRequest_Response>): ListChildOrganizationsRequest_Response {
    const message = createBaseListChildOrganizationsRequest_Response();
    message.childOrganizations = object.childOrganizations?.map((e) => Organization.fromPartial(e)) || [];
    message.request = (object.request !== undefined && object.request !== null)
      ? ListChildOrganizationsRequest.fromPartial(object.request)
      : undefined;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination_Result.fromPartial(object.pagination)
      : undefined;
    return message;
  },
};

function createBaseCreateOrganizationRequest(): CreateOrganizationRequest {
  return {
    parentOrganization: "",
    newOrganization: "",
    displayName: undefined,
    avatar: undefined,
    isBusiness: undefined,
    vatNumber: undefined,
    address: undefined,
    billingName: undefined,
    billingAddress: undefined,
    contact: undefined,
    organizationCreationToken: undefined,
  };
}

export const CreateOrganizationRequest = {
  encode(message: CreateOrganizationRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.parentOrganization !== "") {
      writer.uint32(10).string(message.parentOrganization);
    }
    if (message.newOrganization !== "") {
      writer.uint32(18).string(message.newOrganization);
    }
    if (message.displayName !== undefined) {
      writer.uint32(26).string(message.displayName);
    }
    if (message.avatar !== undefined) {
      Avatar.encode(message.avatar, writer.uint32(90).fork()).ldelim();
    }
    if (message.isBusiness !== undefined) {
      writer.uint32(32).bool(message.isBusiness);
    }
    if (message.vatNumber !== undefined) {
      writer.uint32(42).string(message.vatNumber);
    }
    if (message.address !== undefined) {
      Organization_Address.encode(message.address, writer.uint32(50).fork()).ldelim();
    }
    if (message.billingName !== undefined) {
      writer.uint32(58).string(message.billingName);
    }
    if (message.billingAddress !== undefined) {
      Organization_Address.encode(message.billingAddress, writer.uint32(66).fork()).ldelim();
    }
    if (message.contact !== undefined) {
      Organization_Contact.encode(message.contact, writer.uint32(74).fork()).ldelim();
    }
    if (message.organizationCreationToken !== undefined) {
      writer.uint32(82).string(message.organizationCreationToken);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateOrganizationRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateOrganizationRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.parentOrganization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.newOrganization = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.displayName = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.avatar = Avatar.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.isBusiness = reader.bool();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.vatNumber = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.address = Organization_Address.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.billingName = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.billingAddress = Organization_Address.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.contact = Organization_Contact.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.organizationCreationToken = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<CreateOrganizationRequest>): CreateOrganizationRequest {
    return CreateOrganizationRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<CreateOrganizationRequest>): CreateOrganizationRequest {
    const message = createBaseCreateOrganizationRequest();
    message.parentOrganization = object.parentOrganization ?? "";
    message.newOrganization = object.newOrganization ?? "";
    message.displayName = object.displayName ?? undefined;
    message.avatar = (object.avatar !== undefined && object.avatar !== null)
      ? Avatar.fromPartial(object.avatar)
      : undefined;
    message.isBusiness = object.isBusiness ?? undefined;
    message.vatNumber = object.vatNumber ?? undefined;
    message.address = (object.address !== undefined && object.address !== null)
      ? Organization_Address.fromPartial(object.address)
      : undefined;
    message.billingName = object.billingName ?? undefined;
    message.billingAddress = (object.billingAddress !== undefined && object.billingAddress !== null)
      ? Organization_Address.fromPartial(object.billingAddress)
      : undefined;
    message.contact = (object.contact !== undefined && object.contact !== null)
      ? Organization_Contact.fromPartial(object.contact)
      : undefined;
    message.organizationCreationToken = object.organizationCreationToken ?? undefined;
    return message;
  },
};

function createBaseDeleteOrganizationRequest(): DeleteOrganizationRequest {
  return { parentOrganization: "", organizationToDelete: "" };
}

export const DeleteOrganizationRequest = {
  encode(message: DeleteOrganizationRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.parentOrganization !== "") {
      writer.uint32(10).string(message.parentOrganization);
    }
    if (message.organizationToDelete !== "") {
      writer.uint32(18).string(message.organizationToDelete);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteOrganizationRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteOrganizationRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.parentOrganization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.organizationToDelete = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<DeleteOrganizationRequest>): DeleteOrganizationRequest {
    return DeleteOrganizationRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<DeleteOrganizationRequest>): DeleteOrganizationRequest {
    const message = createBaseDeleteOrganizationRequest();
    message.parentOrganization = object.parentOrganization ?? "";
    message.organizationToDelete = object.organizationToDelete ?? "";
    return message;
  },
};

function createBaseDeleteOrganizationRequest_Response(): DeleteOrganizationRequest_Response {
  return { organizationToDelete: "", organizationsDeleted: undefined };
}

export const DeleteOrganizationRequest_Response = {
  encode(message: DeleteOrganizationRequest_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organizationToDelete !== "") {
      writer.uint32(10).string(message.organizationToDelete);
    }
    if (message.organizationsDeleted !== undefined) {
      OrganizationTree.encode(message.organizationsDeleted, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteOrganizationRequest_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteOrganizationRequest_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organizationToDelete = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.organizationsDeleted = OrganizationTree.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<DeleteOrganizationRequest_Response>): DeleteOrganizationRequest_Response {
    return DeleteOrganizationRequest_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<DeleteOrganizationRequest_Response>): DeleteOrganizationRequest_Response {
    const message = createBaseDeleteOrganizationRequest_Response();
    message.organizationToDelete = object.organizationToDelete ?? "";
    message.organizationsDeleted = (object.organizationsDeleted !== undefined && object.organizationsDeleted !== null)
      ? OrganizationTree.fromPartial(object.organizationsDeleted)
      : undefined;
    return message;
  },
};

function createBaseValidateOrganizationCreationTokenRequest(): ValidateOrganizationCreationTokenRequest {
  return { organizationCreationToken: "" };
}

export const ValidateOrganizationCreationTokenRequest = {
  encode(message: ValidateOrganizationCreationTokenRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organizationCreationToken !== "") {
      writer.uint32(10).string(message.organizationCreationToken);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ValidateOrganizationCreationTokenRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseValidateOrganizationCreationTokenRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organizationCreationToken = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ValidateOrganizationCreationTokenRequest>): ValidateOrganizationCreationTokenRequest {
    return ValidateOrganizationCreationTokenRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ValidateOrganizationCreationTokenRequest>): ValidateOrganizationCreationTokenRequest {
    const message = createBaseValidateOrganizationCreationTokenRequest();
    message.organizationCreationToken = object.organizationCreationToken ?? "";
    return message;
  },
};

function createBaseValidateOrganizationCreationTokenRequest_Response(): ValidateOrganizationCreationTokenRequest_Response {
  return { valid: false };
}

export const ValidateOrganizationCreationTokenRequest_Response = {
  encode(
    message: ValidateOrganizationCreationTokenRequest_Response,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.valid !== false) {
      writer.uint32(8).bool(message.valid);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ValidateOrganizationCreationTokenRequest_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseValidateOrganizationCreationTokenRequest_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.valid = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(
    base?: DeepPartial<ValidateOrganizationCreationTokenRequest_Response>,
  ): ValidateOrganizationCreationTokenRequest_Response {
    return ValidateOrganizationCreationTokenRequest_Response.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<ValidateOrganizationCreationTokenRequest_Response>,
  ): ValidateOrganizationCreationTokenRequest_Response {
    const message = createBaseValidateOrganizationCreationTokenRequest_Response();
    message.valid = object.valid ?? false;
    return message;
  },
};

function createBaseGetOrganizationAvatar(): GetOrganizationAvatar {
  return {};
}

export const GetOrganizationAvatar = {
  encode(_: GetOrganizationAvatar, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetOrganizationAvatar {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetOrganizationAvatar();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<GetOrganizationAvatar>): GetOrganizationAvatar {
    return GetOrganizationAvatar.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<GetOrganizationAvatar>): GetOrganizationAvatar {
    const message = createBaseGetOrganizationAvatar();
    return message;
  },
};

function createBaseGetOrganizationAvatar_Request(): GetOrganizationAvatar_Request {
  return { organizations: [], pagination: undefined };
}

export const GetOrganizationAvatar_Request = {
  encode(message: GetOrganizationAvatar_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.organizations) {
      writer.uint32(10).string(v!);
    }
    if (message.pagination !== undefined) {
      Pagination.encode(message.pagination, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetOrganizationAvatar_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetOrganizationAvatar_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organizations.push(reader.string());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.pagination = Pagination.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<GetOrganizationAvatar_Request>): GetOrganizationAvatar_Request {
    return GetOrganizationAvatar_Request.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<GetOrganizationAvatar_Request>): GetOrganizationAvatar_Request {
    const message = createBaseGetOrganizationAvatar_Request();
    message.organizations = object.organizations?.map((e) => e) || [];
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination.fromPartial(object.pagination)
      : undefined;
    return message;
  },
};

function createBaseGetOrganizationAvatar_Response(): GetOrganizationAvatar_Response {
  return { avatars: {}, pagination: undefined };
}

export const GetOrganizationAvatar_Response = {
  encode(message: GetOrganizationAvatar_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    Object.entries(message.avatars).forEach(([key, value]) => {
      GetOrganizationAvatar_Response_AvatarsEntry.encode({ key: key as any, value }, writer.uint32(10).fork()).ldelim();
    });
    if (message.pagination !== undefined) {
      Pagination_Result.encode(message.pagination, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetOrganizationAvatar_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetOrganizationAvatar_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = GetOrganizationAvatar_Response_AvatarsEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.avatars[entry1.key] = entry1.value;
          }
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.pagination = Pagination_Result.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<GetOrganizationAvatar_Response>): GetOrganizationAvatar_Response {
    return GetOrganizationAvatar_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<GetOrganizationAvatar_Response>): GetOrganizationAvatar_Response {
    const message = createBaseGetOrganizationAvatar_Response();
    message.avatars = Object.entries(object.avatars ?? {}).reduce<{ [key: string]: Avatar }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = Avatar.fromPartial(value);
      }
      return acc;
    }, {});
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination_Result.fromPartial(object.pagination)
      : undefined;
    return message;
  },
};

function createBaseGetOrganizationAvatar_Response_AvatarsEntry(): GetOrganizationAvatar_Response_AvatarsEntry {
  return { key: "", value: undefined };
}

export const GetOrganizationAvatar_Response_AvatarsEntry = {
  encode(message: GetOrganizationAvatar_Response_AvatarsEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      Avatar.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetOrganizationAvatar_Response_AvatarsEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetOrganizationAvatar_Response_AvatarsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = Avatar.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<GetOrganizationAvatar_Response_AvatarsEntry>): GetOrganizationAvatar_Response_AvatarsEntry {
    return GetOrganizationAvatar_Response_AvatarsEntry.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<GetOrganizationAvatar_Response_AvatarsEntry>,
  ): GetOrganizationAvatar_Response_AvatarsEntry {
    const message = createBaseGetOrganizationAvatar_Response_AvatarsEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? Avatar.fromPartial(object.value)
      : undefined;
    return message;
  },
};

/**
 * Organization-related api calls. Currently limited to your own organization; we'll add more for creating and managing
 * sub-organizations later.
 */
export type OrganizationServiceDefinition = typeof OrganizationServiceDefinition;
export const OrganizationServiceDefinition = {
  name: "OrganizationService",
  fullName: "hiber.organization.OrganizationService",
  methods: {
    create: {
      name: "Create",
      requestType: CreateOrganizationRequest,
      requestStream: false,
      responseType: Organization,
      responseStream: false,
      options: {},
    },
    get: {
      name: "Get",
      requestType: GetOrganizationRequest,
      requestStream: false,
      responseType: Organization,
      responseStream: false,
      options: {},
    },
    update: {
      name: "Update",
      requestType: UpdateOrganizationRequest,
      requestStream: false,
      responseType: Organization,
      responseStream: false,
      options: {},
    },
    delete: {
      name: "Delete",
      requestType: DeleteOrganizationRequest,
      requestStream: false,
      responseType: DeleteOrganizationRequest_Response,
      responseStream: false,
      options: {},
    },
    tree: {
      name: "Tree",
      requestType: OrganizationTreeRequest,
      requestStream: false,
      responseType: OrganizationTreeRequest_Response,
      responseStream: false,
      options: {},
    },
    listChildOrganizations: {
      name: "ListChildOrganizations",
      requestType: ListChildOrganizationsRequest,
      requestStream: false,
      responseType: ListChildOrganizationsRequest_Response,
      responseStream: false,
      options: {},
    },
    validateCreationToken: {
      name: "ValidateCreationToken",
      requestType: ValidateOrganizationCreationTokenRequest,
      requestStream: false,
      responseType: ValidateOrganizationCreationTokenRequest_Response,
      responseStream: false,
      options: {},
    },
    getAvatar: {
      name: "GetAvatar",
      requestType: GetOrganizationAvatar_Request,
      requestStream: false,
      responseType: GetOrganizationAvatar_Response,
      responseStream: false,
      options: {},
    },
  },
} as const;

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;
