import { Client, CompatServiceDefinition } from 'nice-grpc-web';
import {
  DeepPartial,
  ListModemMessageBodyParsersRequest,
  ListModemMessageBodyParsersRequest_Response,
  ModemMessageBodyParserServiceDefinition,
  UpdateUploadedModemMessageBodyParserRequest
} from '../customer_compiled/modem_message_body_parser';

type GrpcClientFactoryType = <T extends CompatServiceDefinition>(service: T) => Client<T>;

export default class ModemMessageBodyParserService {
  private static instance: ModemMessageBodyParserService;
  private readonly client: Client<typeof ModemMessageBodyParserServiceDefinition>;

  private constructor(grpcClientFactory: GrpcClientFactoryType) {
    this.client = grpcClientFactory<typeof ModemMessageBodyParserServiceDefinition>(
      ModemMessageBodyParserServiceDefinition,
    );
  }

  static getInstance(grpcClientFactory: GrpcClientFactoryType) {
    if (!ModemMessageBodyParserService.instance) {
      ModemMessageBodyParserService.instance = new ModemMessageBodyParserService(grpcClientFactory);
    }

    return ModemMessageBodyParserService.instance;
  }

  fetchModemMessageBodyParserList(payload: DeepPartial<ListModemMessageBodyParsersRequest>): Promise<ListModemMessageBodyParsersRequest_Response> {
    const request = ListModemMessageBodyParsersRequest.fromPartial(payload);
    return this.client.list(request);
  }

  updateModemMessageBodyParser(payload: any) {
    const { identifier, contentKsy, addFields, removeFields } = payload;
    const request = UpdateUploadedModemMessageBodyParserRequest.fromPartial({
      identifier,
      contentKsy,
      addDataFields: addFields,
      removeDataFields: removeFields,
    });

    return this.client.updateUploaded(request);
  }
}
